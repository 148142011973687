import React, { useState } from 'react'
import Title from 'elements/Typography/Title'
import ForgotPasswordForm from 'views/Auth/ForgotPasswordForm'
import ResetPasswordForm from 'views/Auth/ResetPasswordForm'
import AltActionButton from 'elements/Button/AltActionButton'
import { navigate } from 'gatsby'
import {
  useToast,
  Text
} from '@chakra-ui/core'
import Auth from '@aws-amplify/auth'
import { LOGIN_ROUTE } from 'config/routes'

export default function ForgotPasswordPage () {
  const [requestData, setRequestData] = useState(false)
  const toast = useToast()

  const notice = {
    title: 'Ooops!',
    description: 'There was a problem - please reach out to support',
    status: 'error',
    isClosable: true
  }

  const onReset = async ({ code, password }) => {
    const { email } = requestData
    try {
      await Auth.forgotPasswordSubmit(email, code, password)

      navigate(LOGIN_ROUTE)
    } catch (e) {
      toast({ ...notice, description: e.message })
    }
  }

  const onRequest = async ({ email }) => {
    try {
      await Auth.forgotPassword(email)
      setRequestData({ email })
    } catch (e) {
      toast({ ...notice, description: e.message })
    }
  }
  return (
    <>

      <Title>Reset password</Title>

      {requestData
        ? <Text>
            We&apos;ve sent a code to {requestData.email}. Please enter it here.
        </Text>
        : <Text>
            To reset your password please enter your email address. We will send
            you a confirmation code to enter in the next step.
        </Text>
      }
      {requestData
        ? <ResetPasswordForm onSubmit={onReset}/>
        : <ForgotPasswordForm onSubmit={onRequest}/> }

      <AltActionButton to={LOGIN_ROUTE}> Back to login</AltActionButton>
    </>
  )
}
