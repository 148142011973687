import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import SubmitButton from 'elements/Button/SubmitButton'
import Form from 'elements/Form/Form'
import { isValidPassword } from 'utils/formValidator'
import Link from 'elements/Link/Link'
import {
  LOGIN_ROUTE
} from 'config/routes'

import {
  FormHelperText,
  FormErrorMessage,
  FormControl,
  InputGroup,
  Icon,
  Stack,
  InputLeftElement,
  Input
} from '@chakra-ui/core'

export default function ResetPasswordForm ({ onSubmit }) {
  const { handleSubmit, errors, register, formState } = useForm()

  function validatePassword (value) {
    let error
    if (!value) {
      error = 'Password is required'
    } else if (!isValidPassword(value)) {
      error = 'Password must be 8 chars or longer'
    }
    return error || true
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5} mt={5}>
        <FormControl isInvalid={errors.code}>
          <InputGroup>
            <InputLeftElement><Icon name="secret" color="gray.300" /></InputLeftElement>
            <Input
              name="code"
              placeholder="the code we sent you"
              ref={register({ required: 'Field is required' })}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.code && errors.code.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password}>
          <InputGroup>
            <InputLeftElement><Icon name="lock" color="gray.300" /></InputLeftElement>
            <Input
              type="password"
              name="password"
              placeholder="your new password"
              ref={register({ validate: validatePassword })}
            />
          </InputGroup>
          <FormHelperText>At least 8 characters mix of lowercase, uppercase and number</FormHelperText>
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>

        <SubmitButton
          isLoading={formState.isSubmitting}
        >Submit</SubmitButton>
      </Stack>
    </Form>
  )
}
ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
