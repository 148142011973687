import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/core'
import Link from 'elements/Link/Link'

export default function AltActionButton (props) {
  const { children, to, ...rest } = props

  return (
    <Button
      as={Link}
      to={to}
      mt="4"
      w="100%"
      size="sm"
      color="gray"
      variant="ghost"
      {...rest}>
      {children}
    </Button>
  )
}

AltActionButton.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired
}
